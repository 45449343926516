import React ,{ Component } from 'react';
import { navigate } from "gatsby";




class LoaderPage extends Component {

	componentDidMount() {
		let urlElements = window.location.href.split('?');
		async function exists(url) {
			const result = await fetch(url, { method: 'HEAD' });
			return result.ok;
		}

		async function checkPage() {

			exists(urlElements[1]).then(promise => {
				if(promise){
					clearInterval(interval);
					navigate(urlElements[1]);
				}
			});
		}
		const interval = setInterval(checkPage, 1500);
	}



	render() {
		return (

			<div className="video-wrapper">

				<img src={`/rings.svg`} alt="loader" className="loader" />

			</div>

		);
	}
};

export default LoaderPage;
